import { AlreadyHandledError, NavigationService, pathify } from '@aex/ngx-toolbox';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { head } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, throwError, zip } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { UploadService } from 'src/app/services/upload.service';
import { FilenameUtils } from '../../../../_shared/utils';
import { ConfigService } from '../../../../services/config.service';
import { InstallService } from '../../../../services/install.service';
import {IFnoConfiguration, IHasErrors, IInstall, ImageUpload, ISerialNumber} from '../../../../services/types';
import { ONT_PATTERN, UtilityService } from '../../../../services/utility.service';
import { imageTypeToNamespace } from '../../../../services/utils';

@Component({
	selector: 'app-serial-number',
	templateUrl: './serial-number.component.html',
	styleUrls: ['./serial-number.component.scss'],
})
export class SerialNumberComponent implements IHasErrors {

	@Input() public readonly install: IInstall;
	@ViewChild('cameraInput', { static: false }) private readonly cameraInput: ElementRef;
	@ViewChild('form', { static: true }) private readonly form: NgForm;

	public readonly conf: IFnoConfiguration;
	public readonly ontSerialPattern = ONT_PATTERN;
	public readonly loaderId = 'serial-number-component';
	public displayFsanFootnote: boolean;

	public serialNumbersList: ISerialNumber[] = [];
	public FSANOverrideReasons = DEFAULT_FSAN_OVERRIDE;
	public manualCapture = false;
	public disableFsanValidation: boolean;
	public showFSANOverride: boolean;
	public FSANSaveForLater: boolean;
	selectedReason: string;


	constructor(
		private readonly nav: NavigationService,
		private readonly configService: ConfigService,
		private readonly utilityService: UtilityService,
		private readonly installService: InstallService,
		private readonly uploadService: UploadService,
		private readonly toast: ToastrService,
	) {
		this.conf = this.configService.config;
		this.displayFsanFootnote = this.conf.fsanFootnote;
		this.disableFsanValidation = this.configService.config.disableFsanValidation ?? false;
	}

	public get fsanPhrase(): string {
		return this.conf.customPhrases?.fsan ?? 'FSAN';
	}

	public onBarcodeFileSelected(event: any): void {
		if (event.target.files?.length) {
			const file: File = head(event.target.files);
			const reader = new FileReader();

			reader.onload = () => {
				const type = 'FSAN Serial Number';
				const image = new ImageUpload(
					file, type,
					FilenameUtils.getTimestampFilename(file.name),
					pathify('work_orders', this.install.reference, 'pre_work', imageTypeToNamespace(type)),
				);

				const data: FormData = image.getFormData();
				data.append('save_file', 'true');

				this.utilityService.processBarcode(data).pipe(
					tap(() => this.uploadService.filesChanged()),
				).subscribe(serials => {
					this.serialNumbersList = serials;

					switch (this.serialNumbersList.length) {
						case 0:
							this.toast.warning(`No ${this.fsanPhrase} Number Found`);
							break;
						case 1:
							this.install.extraInfo.serialNumber = this.serialNumbersList[0].value;
							break;
						default:
							this.toast.info('More than one serial number found, please choose from list');
					}

				});
			};
			reader.onloadstart = () => this.nav.startLoading();
			reader.onloadend = () => this.nav.stopLoading();

			reader.readAsDataURL(file);
		}
	}

	private get serialNumber(): string {
		return this.form.value.serialNumber ?? this.install.extraInfo?.serialNumber;
	}

	public get serialNumberValid(): boolean {
		return this.install.extraInfo?.validSerialNumbers?.includes(this.serialNumber);
	}

	public get serialNumberInvalid(): boolean {
		if (this.serialNumber.length > 0 && this.disableFsanValidation)
			return true;

		return this.install.extraInfo?.inValidSerialNumbers?.includes(this.serialNumber);
	}

	private validateFSAN(): Observable<any> {
		const serialNumber = this.serialNumber;
		return this.installService.validateFsan(serialNumber).pipe(
			tap(f => {
				if (f.premise_id[0] !== null && f.errors[0] === 'Device is ready for provisioning')
					this.toast.success(`${this.fsanPhrase} is valid`);
				else
					this.toast.warning(`Another premise is already associated with ${this.fsanPhrase}`);
				this.install.extraInfo.validSerialNumbers.push(serialNumber);
			}),
			catchError(error => {
				let errorMessage: string;
				this.showFSANOverride = this.configService.config.showPreOrder ?? false;
				this.FSANSaveForLater = this.showFSANOverride;

				try {
					errorMessage = `Error: ${error.error.errors[0]}`;
				} catch (error) {
					errorMessage = `Failed to Validate ${this.fsanPhrase}`;
				}
				this.toast.warning(errorMessage);
				this.install.extraInfo.inValidSerialNumbers.push(serialNumber);

				return throwError(new AlreadyHandledError(error));
			}),
		);
	}

	public captureBarcode(): void {
		this.cameraInput.nativeElement.click();
	}
	public captureOverride(): void {
		this.saveWithoutValidation();
	}

	public save(): void {
		this.nav.startLoading({ loader: this.loaderId });
		const FSANValidationResult = this.validateFSAN();
		FSANValidationResult.pipe(
			switchMap(() => zip(
				// Only run the relevant requests, according to what is dirty
				this.form.controls.serialNumber.dirty
					? this.installService.updateInstall(this.install.id, this.install.type_id, {
						account_id: `${this.install.account_id}`,
						serial_number: `${this.form.value.serialNumber}`,
						status_id: this.configService.beginStatus(this.install.type_id).toString(),
					})
					: EMPTY,
				this.form.controls.ispReference?.dirty
					? this.installService.updateAccount(this.install.account_id, { isp_reference: this.form.value.ispReference })
					: EMPTY,
			).pipe(tap(() => this.toast.success('Serials updated successfully'))),
			),
			finalize(() => this.nav.stopLoading({ loader: this.loaderId })),
		).subscribe();
	}

	public saveWithoutValidation(): void {
		this.nav.startLoading({ loader: this.loaderId });
		if (this.form.controls.serialNumber.dirty)
			if (this.FSANSaveForLater)
				this.installService.updateInstall(this.install.id, this.install.type_id, {
					account_id: `${this.install.account_id}`,
					serial_number: `${this.form.value.serialNumber}`,
					comments: `${this.selectedReason}`,
					status_id: this.configService.beginStatus(this.install.type_id).toString(),
				}).pipe(
						tap(() => this.toast.success('Serial Number override successfully')),
						finalize(() => this.nav.stopLoading({ loader: this.loaderId })),
				).subscribe();
			else
				this.installService.updateInstall(this.install.id, this.install.type_id, {
					account_id: `${this.install.account_id}`,
					serial_number: `${this.form.value.serialNumber}`,
					status_id: this.configService.beginStatus(this.install.type_id).toString(),
				}).pipe(
						tap(() => this.toast.success('Serial Number updated successfully')),
						finalize(() => this.nav.stopLoading({ loader: this.loaderId })),
				).subscribe();
	}

	public onSubmit(): void {
		if (this.disableFsanValidation)
			this.saveWithoutValidation();
		else
			this.save();
	}

	public get errors(): string[] {
		const result = [];
		if (!this.serialNumber)
			result.push(`No ${this.fsanPhrase} Number`);
		else if (this.serialNumberInvalid)
			result.push(`${this.fsanPhrase} Number not valid`);
		return result;
	}

	protected readonly alert = alert;
}
const DEFAULT_FSAN_OVERRIDE = [
	'Install Complete - No FSAN Verification',
	'Install Complete - Maintenance Required',
	'Install Incomplete - Maintenance Required',
];

